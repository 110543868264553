/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AxContact } from '../models/ax-contact';
import { AxPaginatedResultDto } from '../models/ax-paginated-result-dto';
import { AxProject } from '../models/ax-project';
import { AxSearchResultContactDto } from '../models/ax-search-result-contact-dto';

@Injectable({
  providedIn: 'root',
})
export class AxContactsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation contactsControllerFindAll
   */
  static readonly ContactsControllerFindAllPath = '/v1/contacts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactsControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactsControllerFindAll$Response(params?: {
    page?: any;
    limit?: any;
    sortBy?: any;
    searchBy?: any;
    search?: any;
    filter?: any;
    path?: any;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AxPaginatedResultDto>> {

    const rb = new RequestBuilder(this.rootUrl, AxContactsService.ContactsControllerFindAllPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('searchBy', params.searchBy, {});
      rb.query('search', params.search, {});
      rb.query('filter', params.filter, {});
      rb.query('path', params.path, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxPaginatedResultDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `contactsControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactsControllerFindAll(params?: {
    page?: any;
    limit?: any;
    sortBy?: any;
    searchBy?: any;
    search?: any;
    filter?: any;
    path?: any;
    context?: HttpContext
  }
): Observable<AxPaginatedResultDto> {

    return this.contactsControllerFindAll$Response(params).pipe(
      map((r: StrictHttpResponse<AxPaginatedResultDto>) => r.body as AxPaginatedResultDto)
    );
  }

  /**
   * Path part for operation contactsControllerSearch
   */
  static readonly ContactsControllerSearchPath = '/v1/contacts/search/{keyword}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactsControllerSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactsControllerSearch$Response(params: {
    keyword: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AxSearchResultContactDto>>> {

    const rb = new RequestBuilder(this.rootUrl, AxContactsService.ContactsControllerSearchPath, 'get');
    if (params) {
      rb.path('keyword', params.keyword, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxSearchResultContactDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `contactsControllerSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactsControllerSearch(params: {
    keyword: string;
    context?: HttpContext
  }
): Observable<Array<AxSearchResultContactDto>> {

    return this.contactsControllerSearch$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AxSearchResultContactDto>>) => r.body as Array<AxSearchResultContactDto>)
    );
  }

  /**
   * Path part for operation contactsControllerFindOne
   */
  static readonly ContactsControllerFindOnePath = '/v1/contacts/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactsControllerFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactsControllerFindOne$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AxContact>> {

    const rb = new RequestBuilder(this.rootUrl, AxContactsService.ContactsControllerFindOnePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxContact>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `contactsControllerFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactsControllerFindOne(params: {
    id: string;
    context?: HttpContext
  }
): Observable<AxContact> {

    return this.contactsControllerFindOne$Response(params).pipe(
      map((r: StrictHttpResponse<AxContact>) => r.body as AxContact)
    );
  }

  /**
   * Path part for operation contactsControllerFindProjects
   */
  static readonly ContactsControllerFindProjectsPath = '/v1/contacts/{id}/projects';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactsControllerFindProjects()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactsControllerFindProjects$Response(params: {
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AxProject>>> {

    const rb = new RequestBuilder(this.rootUrl, AxContactsService.ContactsControllerFindProjectsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxProject>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `contactsControllerFindProjects$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactsControllerFindProjects(params: {
    id: number;
    context?: HttpContext
  }
): Observable<Array<AxProject>> {

    return this.contactsControllerFindProjects$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AxProject>>) => r.body as Array<AxProject>)
    );
  }

}
