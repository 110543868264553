/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AxPaginatedResultDto } from '../models/ax-paginated-result-dto';
import { AxUpdateUserDto } from '../models/ax-update-user-dto';
import { AxUser } from '../models/ax-user';

@Injectable({
  providedIn: 'root',
})
export class AxUsersService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation usersControllerFindAllPaginated
   */
  static readonly UsersControllerFindAllPaginatedPath = '/v1/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersControllerFindAllPaginated()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersControllerFindAllPaginated$Response(params?: {
    page?: any;
    limit?: any;
    sortBy?: any;
    searchBy?: any;
    search?: any;
    filter?: any;
    path?: any;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AxPaginatedResultDto>> {

    const rb = new RequestBuilder(this.rootUrl, AxUsersService.UsersControllerFindAllPaginatedPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('searchBy', params.searchBy, {});
      rb.query('search', params.search, {});
      rb.query('filter', params.filter, {});
      rb.query('path', params.path, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxPaginatedResultDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersControllerFindAllPaginated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersControllerFindAllPaginated(params?: {
    page?: any;
    limit?: any;
    sortBy?: any;
    searchBy?: any;
    search?: any;
    filter?: any;
    path?: any;
    context?: HttpContext
  }
): Observable<AxPaginatedResultDto> {

    return this.usersControllerFindAllPaginated$Response(params).pipe(
      map((r: StrictHttpResponse<AxPaginatedResultDto>) => r.body as AxPaginatedResultDto)
    );
  }

  /**
   * Path part for operation usersControllerFindAll
   */
  static readonly UsersControllerFindAllPath = '/v1/users/withPermissions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersControllerFindAll$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AxUser>>> {

    const rb = new RequestBuilder(this.rootUrl, AxUsersService.UsersControllerFindAllPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxUser>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersControllerFindAll(params?: {
    context?: HttpContext
  }
): Observable<Array<AxUser>> {

    return this.usersControllerFindAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AxUser>>) => r.body as Array<AxUser>)
    );
  }

  /**
   * Path part for operation usersControllerFindNextBirthdays
   */
  static readonly UsersControllerFindNextBirthdaysPath = '/v1/users/nextBirthdays';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersControllerFindNextBirthdays()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersControllerFindNextBirthdays$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<{
}>>> {

    const rb = new RequestBuilder(this.rootUrl, AxUsersService.UsersControllerFindNextBirthdaysPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<{
        }>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersControllerFindNextBirthdays$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersControllerFindNextBirthdays(params?: {
    context?: HttpContext
  }
): Observable<Array<{
}>> {

    return this.usersControllerFindNextBirthdays$Response(params).pipe(
      map((r: StrictHttpResponse<Array<{
}>>) => r.body as Array<{
}>)
    );
  }

  /**
   * Path part for operation usersControllerFindOne
   */
  static readonly UsersControllerFindOnePath = '/v1/users/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersControllerFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersControllerFindOne$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AxUser>> {

    const rb = new RequestBuilder(this.rootUrl, AxUsersService.UsersControllerFindOnePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxUser>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersControllerFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersControllerFindOne(params: {
    id: string;
    context?: HttpContext
  }
): Observable<AxUser> {

    return this.usersControllerFindOne$Response(params).pipe(
      map((r: StrictHttpResponse<AxUser>) => r.body as AxUser)
    );
  }

  /**
   * Path part for operation usersControllerUpdateRoletypes
   */
  static readonly UsersControllerUpdateRoletypesPath = '/v1/users/updateRoletypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersControllerUpdateRoletypes()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersControllerUpdateRoletypes$Response(params: {
    context?: HttpContext
    body: AxUpdateUserDto
  }
): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, AxUsersService.UsersControllerUpdateRoletypesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usersControllerUpdateRoletypes$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersControllerUpdateRoletypes(params: {
    context?: HttpContext
    body: AxUpdateUserDto
  }
): Observable<{
}> {

    return this.usersControllerUpdateRoletypes$Response(params).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

}
