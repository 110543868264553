import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { StateClear } from 'ngxs-reset-plugin';
import { BehaviorSubject } from 'rxjs';
import { AxApiBaseService } from 'src/app/ax-commons-api/ax-api-base.service';
import { LoggerService } from 'src/app/ax-commons-logger/logger/logger.service';

@Injectable({
  providedIn: 'root',
})
export class AxTokenService extends AxApiBaseService {
  public accessToken$ = new BehaviorSubject<string>(null);
  public refreshToken$ = new BehaviorSubject<string>(null);
  private storageKeyForAccessToken = 'ax-access-token';
  private storageKeyForRefreshToken = 'ax-refresh-token';

  constructor(
    protected httpClient: HttpClient,
    private store: Store,
  ) {
    super(httpClient);
  }

  /**
   * Stores access token in secure storage.
   */
  public async setAccessToken(accessToken: string): Promise<boolean> {
    LoggerService.LOG(this, 'setAccessToken', accessToken);
    this.accessToken$.next(accessToken);
    const tokenEntry = await SecureStoragePlugin.set({key: this.storageKeyForAccessToken, value: accessToken});
    return tokenEntry.value;
  }

  /**
   * Stores refresh token in secure storage.
   */
  public async setRefreshToken(refreshToken: string): Promise<boolean> {
    LoggerService.LOG(this, 'setRefreshToken', refreshToken);
    this.refreshToken$.next(refreshToken);
    const tokenEntry = await SecureStoragePlugin.set({key: this.storageKeyForRefreshToken, value: refreshToken});
    return tokenEntry.value;
  }

  /**
   * Returns access token from secure storage.
   */
  public async getAccessToken(): Promise<string> {
    try {
      const tokenEntry = await SecureStoragePlugin.get({key: this.storageKeyForAccessToken});
      this.accessToken$.next(tokenEntry.value);
      return tokenEntry.value;
    } catch (secureStorageException) {
      return null;
    }
  }

  /**
   * Returns refresh token from secure storage.
   */
  public async getRefreshToken(): Promise<string> {
    try {
      const tokenEntry = await SecureStoragePlugin.get({key: this.storageKeyForRefreshToken});
      this.refreshToken$.next(tokenEntry.value);
      return tokenEntry.value;
    } catch (secureStorageException) {
      return null;
    }
  }

  /**
   * Logout the user by deleting his token.
   */
  public async logout(): Promise<{ value: boolean }> {
    this.accessToken$.next(null);
    this.refreshToken$.next(null);
    this.store.dispatch(
      new StateClear(),
    );
    try {
      await SecureStoragePlugin.remove({key: this.storageKeyForRefreshToken});
      return SecureStoragePlugin.remove({key: this.storageKeyForAccessToken});
    } catch (allFineException) {
      return {value: true};
    }
  }
}
