/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AxCreateElectricalMeasurementDto } from '../models/ax-create-electrical-measurement-dto';
import { AxElectricalMeasurement } from '../models/ax-electrical-measurement';
import { AxElectricalMeasurementCable } from '../models/ax-electrical-measurement-cable';
import { AxElectricalMeasurementDevice } from '../models/ax-electrical-measurement-device';
import { AxElectricalMeasurementType } from '../models/ax-electrical-measurement-type';
import { AxUpdateElectricalMeasurementDto } from '../models/ax-update-electrical-measurement-dto';

@Injectable({
  providedIn: 'root',
})
export class AxElectricalMeasurementsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation electricalMeasurementsControllerFindMy
   */
  static readonly ElectricalMeasurementsControllerFindMyPath = '/v1/electrical-measurements/my';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `electricalMeasurementsControllerFindMy()` instead.
   *
   * This method doesn't expect any request body.
   */
  electricalMeasurementsControllerFindMy$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AxElectricalMeasurement>>> {

    const rb = new RequestBuilder(this.rootUrl, AxElectricalMeasurementsService.ElectricalMeasurementsControllerFindMyPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxElectricalMeasurement>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `electricalMeasurementsControllerFindMy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  electricalMeasurementsControllerFindMy(params?: {
    context?: HttpContext
  }
): Observable<Array<AxElectricalMeasurement>> {

    return this.electricalMeasurementsControllerFindMy$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AxElectricalMeasurement>>) => r.body as Array<AxElectricalMeasurement>)
    );
  }

  /**
   * Path part for operation electricalMeasurementsControllerFindAllForProject
   */
  static readonly ElectricalMeasurementsControllerFindAllForProjectPath = '/v1/electrical-measurements/byProjectId/{projectId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `electricalMeasurementsControllerFindAllForProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  electricalMeasurementsControllerFindAllForProject$Response(params: {
    projectId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AxElectricalMeasurement>>> {

    const rb = new RequestBuilder(this.rootUrl, AxElectricalMeasurementsService.ElectricalMeasurementsControllerFindAllForProjectPath, 'get');
    if (params) {
      rb.path('projectId', params.projectId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxElectricalMeasurement>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `electricalMeasurementsControllerFindAllForProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  electricalMeasurementsControllerFindAllForProject(params: {
    projectId: number;
    context?: HttpContext
  }
): Observable<Array<AxElectricalMeasurement>> {

    return this.electricalMeasurementsControllerFindAllForProject$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AxElectricalMeasurement>>) => r.body as Array<AxElectricalMeasurement>)
    );
  }

  /**
   * Path part for operation electricalMeasurementsControllerGetDevices
   */
  static readonly ElectricalMeasurementsControllerGetDevicesPath = '/v1/electrical-measurements/devices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `electricalMeasurementsControllerGetDevices()` instead.
   *
   * This method doesn't expect any request body.
   */
  electricalMeasurementsControllerGetDevices$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AxElectricalMeasurementDevice>>> {

    const rb = new RequestBuilder(this.rootUrl, AxElectricalMeasurementsService.ElectricalMeasurementsControllerGetDevicesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxElectricalMeasurementDevice>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `electricalMeasurementsControllerGetDevices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  electricalMeasurementsControllerGetDevices(params?: {
    context?: HttpContext
  }
): Observable<Array<AxElectricalMeasurementDevice>> {

    return this.electricalMeasurementsControllerGetDevices$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AxElectricalMeasurementDevice>>) => r.body as Array<AxElectricalMeasurementDevice>)
    );
  }

  /**
   * Path part for operation electricalMeasurementsControllerGetTypes
   */
  static readonly ElectricalMeasurementsControllerGetTypesPath = '/v1/electrical-measurements/types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `electricalMeasurementsControllerGetTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  electricalMeasurementsControllerGetTypes$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AxElectricalMeasurementType>>> {

    const rb = new RequestBuilder(this.rootUrl, AxElectricalMeasurementsService.ElectricalMeasurementsControllerGetTypesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxElectricalMeasurementType>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `electricalMeasurementsControllerGetTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  electricalMeasurementsControllerGetTypes(params?: {
    context?: HttpContext
  }
): Observable<Array<AxElectricalMeasurementType>> {

    return this.electricalMeasurementsControllerGetTypes$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AxElectricalMeasurementType>>) => r.body as Array<AxElectricalMeasurementType>)
    );
  }

  /**
   * Path part for operation electricalMeasurementsControllerGetCables
   */
  static readonly ElectricalMeasurementsControllerGetCablesPath = '/v1/electrical-measurements/cables';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `electricalMeasurementsControllerGetCables()` instead.
   *
   * This method doesn't expect any request body.
   */
  electricalMeasurementsControllerGetCables$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AxElectricalMeasurementCable>>> {

    const rb = new RequestBuilder(this.rootUrl, AxElectricalMeasurementsService.ElectricalMeasurementsControllerGetCablesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxElectricalMeasurementCable>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `electricalMeasurementsControllerGetCables$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  electricalMeasurementsControllerGetCables(params?: {
    context?: HttpContext
  }
): Observable<Array<AxElectricalMeasurementCable>> {

    return this.electricalMeasurementsControllerGetCables$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AxElectricalMeasurementCable>>) => r.body as Array<AxElectricalMeasurementCable>)
    );
  }

  /**
   * Path part for operation electricalMeasurementsControllerFindOne
   */
  static readonly ElectricalMeasurementsControllerFindOnePath = '/v1/electrical-measurements/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `electricalMeasurementsControllerFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  electricalMeasurementsControllerFindOne$Response(params: {
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AxElectricalMeasurement>>> {

    const rb = new RequestBuilder(this.rootUrl, AxElectricalMeasurementsService.ElectricalMeasurementsControllerFindOnePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxElectricalMeasurement>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `electricalMeasurementsControllerFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  electricalMeasurementsControllerFindOne(params: {
    id: number;
    context?: HttpContext
  }
): Observable<Array<AxElectricalMeasurement>> {

    return this.electricalMeasurementsControllerFindOne$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AxElectricalMeasurement>>) => r.body as Array<AxElectricalMeasurement>)
    );
  }

  /**
   * Path part for operation electricalMeasurementsControllerDelete
   */
  static readonly ElectricalMeasurementsControllerDeletePath = '/v1/electrical-measurements/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `electricalMeasurementsControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  electricalMeasurementsControllerDelete$Response(params: {
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AxElectricalMeasurement>> {

    const rb = new RequestBuilder(this.rootUrl, AxElectricalMeasurementsService.ElectricalMeasurementsControllerDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxElectricalMeasurement>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `electricalMeasurementsControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  electricalMeasurementsControllerDelete(params: {
    id: number;
    context?: HttpContext
  }
): Observable<AxElectricalMeasurement> {

    return this.electricalMeasurementsControllerDelete$Response(params).pipe(
      map((r: StrictHttpResponse<AxElectricalMeasurement>) => r.body as AxElectricalMeasurement)
    );
  }

  /**
   * Path part for operation electricalMeasurementsControllerUpdate
   */
  static readonly ElectricalMeasurementsControllerUpdatePath = '/v1/electrical-measurements/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `electricalMeasurementsControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  electricalMeasurementsControllerUpdate$Response(params: {
    id: number;
    context?: HttpContext
    body: AxUpdateElectricalMeasurementDto
  }
): Observable<StrictHttpResponse<AxElectricalMeasurement>> {

    const rb = new RequestBuilder(this.rootUrl, AxElectricalMeasurementsService.ElectricalMeasurementsControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxElectricalMeasurement>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `electricalMeasurementsControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  electricalMeasurementsControllerUpdate(params: {
    id: number;
    context?: HttpContext
    body: AxUpdateElectricalMeasurementDto
  }
): Observable<AxElectricalMeasurement> {

    return this.electricalMeasurementsControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<AxElectricalMeasurement>) => r.body as AxElectricalMeasurement)
    );
  }

  /**
   * Path part for operation electricalMeasurementsControllerCreate
   */
  static readonly ElectricalMeasurementsControllerCreatePath = '/v1/electrical-measurements';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `electricalMeasurementsControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  electricalMeasurementsControllerCreate$Response(params: {
    context?: HttpContext
    body: AxCreateElectricalMeasurementDto
  }
): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, AxElectricalMeasurementsService.ElectricalMeasurementsControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `electricalMeasurementsControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  electricalMeasurementsControllerCreate(params: {
    context?: HttpContext
    body: AxCreateElectricalMeasurementDto
  }
): Observable<{
}> {

    return this.electricalMeasurementsControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

}
