/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AxGenerateFromImagesDto } from '../models/ax-generate-from-images-dto';
import { AxGenerateFromTemplateDto } from '../models/ax-generate-from-template-dto';

@Injectable({
  providedIn: 'root',
})
export class AxDocumentsGeneratorService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation documentsGeneratorControllerGenerateFromTemplate
   */
  static readonly DocumentsGeneratorControllerGenerateFromTemplatePath = '/v1/documents-generator';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentsGeneratorControllerGenerateFromTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentsGeneratorControllerGenerateFromTemplate$Response(params: {
    format: string;
    context?: HttpContext
    body: AxGenerateFromTemplateDto
  }
): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, AxDocumentsGeneratorService.DocumentsGeneratorControllerGenerateFromTemplatePath, 'post');
    if (params) {
      rb.query('format', params.format, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `documentsGeneratorControllerGenerateFromTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentsGeneratorControllerGenerateFromTemplate(params: {
    format: string;
    context?: HttpContext
    body: AxGenerateFromTemplateDto
  }
): Observable<{
}> {

    return this.documentsGeneratorControllerGenerateFromTemplate$Response(params).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation documentsGeneratorControllerGeneratePdfFromImages
   */
  static readonly DocumentsGeneratorControllerGeneratePdfFromImagesPath = '/v1/documents-generator/generatePdfFromImages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentsGeneratorControllerGeneratePdfFromImages()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentsGeneratorControllerGeneratePdfFromImages$Response(params: {
    context?: HttpContext
    body: AxGenerateFromImagesDto
  }
): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, AxDocumentsGeneratorService.DocumentsGeneratorControllerGeneratePdfFromImagesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `documentsGeneratorControllerGeneratePdfFromImages$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentsGeneratorControllerGeneratePdfFromImages(params: {
    context?: HttpContext
    body: AxGenerateFromImagesDto
  }
): Observable<{
}> {

    return this.documentsGeneratorControllerGeneratePdfFromImages$Response(params).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

}
