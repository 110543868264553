<div class="dropdown">
  <div class="dropdown-header flex items-center justify-between">
    <div>
      <div class="dropdown-heading">Benachrichtigungen</div>
      <div class="dropdown-subheading">Du hast {{ notifications.length }} neue Benachrichtigungen.</div>
    </div>
  </div>

  <div class="dropdown-content">
    <a *ngFor="let notification of notifications; trackBy: trackById"
       [class.read]="notification.read"
       [routerLink]="[]"
       class="notification flex items-center"
       matRipple>
      <mat-icon [ngClass]="notification.colorClass"
                [svgIcon]="notification.icon"
                class="notification-icon flex-none"></mat-icon>
      <div class="flex-auto">
        <div class="notification-label">{{ notification.label }}</div>
        <div class="notification-description">{{ notification.datetime | relativeDateTime }}</div>
      </div>
      <mat-icon class="notification-chevron flex-none" svgIcon="mat:chevron_right"></mat-icon>
    </a>
  </div>
</div>
