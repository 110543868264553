import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AxAuthService } from 'src/app/_generated/axova-rest-api';
import { AxTokenService } from 'src/app/ax-commons-api/ax-token.service';
import { LoggerService } from 'src/app/ax-commons-logger/logger/logger.service';
import { PopoverRef } from '../popover/popover-ref';

@Component({
  selector: 'vex-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {

  constructor(
    private readonly popoverRef: PopoverRef,
    private axAuthService: AxAuthService,
    private axTokenService: AxTokenService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
  }

  close(): void {
    /** Wait for animation to complete and then close */
    setTimeout(() => this.popoverRef.close(), 250);
  }

  async logout() {
    try {
      this.close();
      await this.axAuthService.authControllerLogout().toPromise();
      await this.axTokenService.logout();
    } catch (logoutException) {
      LoggerService.LOG(this, 'logoutException', logoutException);
    }
    return this.router.navigateByUrl('/auth/login').then();
  }
}
