/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AxAuthenticateWithSecretDto } from '../models/ax-authenticate-with-secret-dto';
import { AxQrCodeContentDto } from '../models/ax-qr-code-content-dto';
import { AxSendLeadDto } from '../models/ax-send-lead-dto';
import { AxSendMailDto } from '../models/ax-send-mail-dto';
import { AxSendMailFromTemplateDto } from '../models/ax-send-mail-from-template-dto';
import { AxUpdateWelcomescreenDto } from '../models/ax-update-welcomescreen-dto';

@Injectable({
  providedIn: 'root',
})
export class AxHelpersService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation helperControllerSendMail
   */
  static readonly HelperControllerSendMailPath = '/v1/helpers/sendMail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `helperControllerSendMail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  helperControllerSendMail$Response(params: {
    context?: HttpContext
    body: AxSendMailDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AxHelpersService.HelperControllerSendMailPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `helperControllerSendMail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  helperControllerSendMail(params: {
    context?: HttpContext
    body: AxSendMailDto
  }
): Observable<boolean> {

    return this.helperControllerSendMail$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation helperControllerSendLead
   */
  static readonly HelperControllerSendLeadPath = '/v1/helpers/sendLead';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `helperControllerSendLead()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  helperControllerSendLead$Response(params: {
    context?: HttpContext
    body: AxSendLeadDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AxHelpersService.HelperControllerSendLeadPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `helperControllerSendLead$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  helperControllerSendLead(params: {
    context?: HttpContext
    body: AxSendLeadDto
  }
): Observable<boolean> {

    return this.helperControllerSendLead$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation helperControllerSendMailFromTemplate
   */
  static readonly HelperControllerSendMailFromTemplatePath = '/v1/helpers/sendMailFromTemplate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `helperControllerSendMailFromTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  helperControllerSendMailFromTemplate$Response(params: {
    context?: HttpContext
    body: AxSendMailFromTemplateDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AxHelpersService.HelperControllerSendMailFromTemplatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `helperControllerSendMailFromTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  helperControllerSendMailFromTemplate(params: {
    context?: HttpContext
    body: AxSendMailFromTemplateDto
  }
): Observable<boolean> {

    return this.helperControllerSendMailFromTemplate$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation helperControllerGenerateLatitudeAndLongitudeForAllProjects
   */
  static readonly HelperControllerGenerateLatitudeAndLongitudeForAllProjectsPath = '/v1/helpers/generateLatitudeAndLongitudeForAllProjects';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `helperControllerGenerateLatitudeAndLongitudeForAllProjects()` instead.
   *
   * This method doesn't expect any request body.
   */
  helperControllerGenerateLatitudeAndLongitudeForAllProjects$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, AxHelpersService.HelperControllerGenerateLatitudeAndLongitudeForAllProjectsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `helperControllerGenerateLatitudeAndLongitudeForAllProjects$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  helperControllerGenerateLatitudeAndLongitudeForAllProjects(params?: {
    context?: HttpContext
  }
): Observable<{
}> {

    return this.helperControllerGenerateLatitudeAndLongitudeForAllProjects$Response(params).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation helperControllerGenerateQrCode
   */
  static readonly HelperControllerGenerateQrCodePath = '/v1/helpers/generateQrCode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `helperControllerGenerateQrCode()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  helperControllerGenerateQrCode$Response(params: {
    context?: HttpContext
    body: AxQrCodeContentDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AxHelpersService.HelperControllerGenerateQrCodePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `helperControllerGenerateQrCode$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  helperControllerGenerateQrCode(params: {
    context?: HttpContext
    body: AxQrCodeContentDto
  }
): Observable<void> {

    return this.helperControllerGenerateQrCode$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation helperControllerGetProjectImportForGoogleAppScripts
   */
  static readonly HelperControllerGetProjectImportForGoogleAppScriptsPath = '/v1/helpers/getProjectImportForGoogleAppScripts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `helperControllerGetProjectImportForGoogleAppScripts()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  helperControllerGetProjectImportForGoogleAppScripts$Response(params: {
    context?: HttpContext
    body: AxAuthenticateWithSecretDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AxHelpersService.HelperControllerGetProjectImportForGoogleAppScriptsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `helperControllerGetProjectImportForGoogleAppScripts$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  helperControllerGetProjectImportForGoogleAppScripts(params: {
    context?: HttpContext
    body: AxAuthenticateWithSecretDto
  }
): Observable<void> {

    return this.helperControllerGetProjectImportForGoogleAppScripts$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation helperControllerGetUserImportForGoogleAppScripts
   */
  static readonly HelperControllerGetUserImportForGoogleAppScriptsPath = '/v1/helpers/getUserImportForGoogleAppScripts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `helperControllerGetUserImportForGoogleAppScripts()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  helperControllerGetUserImportForGoogleAppScripts$Response(params: {
    department: string;
    active: boolean;
    context?: HttpContext
    body: AxAuthenticateWithSecretDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AxHelpersService.HelperControllerGetUserImportForGoogleAppScriptsPath, 'post');
    if (params) {
      rb.query('department', params.department, {});
      rb.query('active', params.active, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `helperControllerGetUserImportForGoogleAppScripts$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  helperControllerGetUserImportForGoogleAppScripts(params: {
    department: string;
    active: boolean;
    context?: HttpContext
    body: AxAuthenticateWithSecretDto
  }
): Observable<void> {

    return this.helperControllerGetUserImportForGoogleAppScripts$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation helperControllerGetTaskImportForGoogleAppScripts
   */
  static readonly HelperControllerGetTaskImportForGoogleAppScriptsPath = '/v1/helpers/getTaskImportForGoogleAppScripts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `helperControllerGetTaskImportForGoogleAppScripts()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  helperControllerGetTaskImportForGoogleAppScripts$Response(params: {
    context?: HttpContext
    body: AxAuthenticateWithSecretDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AxHelpersService.HelperControllerGetTaskImportForGoogleAppScriptsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `helperControllerGetTaskImportForGoogleAppScripts$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  helperControllerGetTaskImportForGoogleAppScripts(params: {
    context?: HttpContext
    body: AxAuthenticateWithSecretDto
  }
): Observable<void> {

    return this.helperControllerGetTaskImportForGoogleAppScripts$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation viewneoControllerUpdateWelcomescreen
   */
  static readonly ViewneoControllerUpdateWelcomescreenPath = '/v1/helpers/viewneo/updateWelcomescreen';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `viewneoControllerUpdateWelcomescreen()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  viewneoControllerUpdateWelcomescreen$Response(params: {
    context?: HttpContext
    body: AxUpdateWelcomescreenDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AxHelpersService.ViewneoControllerUpdateWelcomescreenPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `viewneoControllerUpdateWelcomescreen$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  viewneoControllerUpdateWelcomescreen(params: {
    context?: HttpContext
    body: AxUpdateWelcomescreenDto
  }
): Observable<void> {

    return this.viewneoControllerUpdateWelcomescreen$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
