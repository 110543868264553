/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AxApiService } from './services/ax-api.service';
import { AxProjectsService } from './services/ax-projects.service';
import { AxContactsService } from './services/ax-contacts.service';
import { AxDocumentsService } from './services/ax-documents.service';
import { AxUsersService } from './services/ax-users.service';
import { AxHelpersService } from './services/ax-helpers.service';
import { AxGoogleCalendarApiService } from './services/ax-google-calendar-api.service';
import { AxReservationsService } from './services/ax-reservations.service';
import { AxReservationtypesService } from './services/ax-reservationtypes.service';
import { AxLocationsService } from './services/ax-locations.service';
import { AxRoletypesService } from './services/ax-roletypes.service';
import { AxStatusesService } from './services/ax-statuses.service';
import { AxNewsService } from './services/ax-news.service';
import { AxAudiencesService } from './services/ax-audiences.service';
import { AxItemsService } from './services/ax-items.service';
import { AxDocumentsGeneratorService } from './services/ax-documents-generator.service';
import { AxTimerecorddetailsService } from './services/ax-timerecorddetails.service';
import { AxSearchService } from './services/ax-search.service';
import { AxFilesService } from './services/ax-files.service';
import { AxAppDevicesService } from './services/ax-app-devices.service';
import { AxPushNotificationTokensService } from './services/ax-push-notification-tokens.service';
import { AxElectricalMeasurementsService } from './services/ax-electrical-measurements.service';
import { AxMyService } from './services/ax-my.service';
import { AxAbsencesService } from './services/ax-absences.service';
import { AxProjectrevenuesService } from './services/ax-projectrevenues.service';
import { AxEventsService } from './services/ax-events.service';
import { AxEventInvitationsService } from './services/ax-event-invitations.service';
import { AxHealthService } from './services/ax-health.service';
import { AxCategoriesService } from './services/ax-categories.service';
import { AxAuthService } from './services/ax-auth.service';
import { AxUploadsService } from './services/ax-uploads.service';
import { AxDocumentsubtypesService } from './services/ax-documentsubtypes.service';
import { AxTimerecordtypesService } from './services/ax-timerecordtypes.service';
import { AxCommentsService } from './services/ax-comments.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AxApiService,
    AxProjectsService,
    AxContactsService,
    AxDocumentsService,
    AxUsersService,
    AxHelpersService,
    AxGoogleCalendarApiService,
    AxReservationsService,
    AxReservationtypesService,
    AxLocationsService,
    AxRoletypesService,
    AxStatusesService,
    AxNewsService,
    AxAudiencesService,
    AxItemsService,
    AxDocumentsGeneratorService,
    AxTimerecorddetailsService,
    AxSearchService,
    AxFilesService,
    AxAppDevicesService,
    AxPushNotificationTokensService,
    AxElectricalMeasurementsService,
    AxMyService,
    AxAbsencesService,
    AxProjectrevenuesService,
    AxEventsService,
    AxEventInvitationsService,
    AxHealthService,
    AxCategoriesService,
    AxAuthService,
    AxUploadsService,
    AxDocumentsubtypesService,
    AxTimerecordtypesService,
    AxCommentsService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
