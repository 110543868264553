import { Injectable } from '@angular/core';
import { Action, NgxsAfterBootstrap, Selector, State, StateContext } from '@ngxs/store';
import { AxContact } from 'src/app/_generated/axova-rest-api/models/ax-contact';
import { AxUser } from 'src/app/_generated/axova-rest-api/models/ax-user';
import { AppStateSetProfile } from 'src/app/ngxs/app/app.actions';
import { AppStateModel } from 'src/app/ngxs/app/app.state.model';

const STATE_NAME = 'axova__appState';
const DEFAULT_STATE: AppStateModel = {
  profile: null,
};

@State<AppStateModel>({
  name: STATE_NAME,
  defaults: DEFAULT_STATE,
})
@Injectable()
export class AppState implements NgxsAfterBootstrap {

  constructor() {
  }

  @Selector()
  static profile(state: AppStateModel) {
    return state.profile;
  }

  @Selector()
  static axUser(state: AppStateModel): AxUser {
    return state.profile.meta as AxUser;
  }

  @Selector()
  static roles(state: AppStateModel): string[] {
    return state.profile.roles;
  }

  @Selector()
  static permissions(state: AppStateModel): string[] {
    return state.profile.permissions;
  }

  @Action(AppStateSetProfile)
  setAuth0User(ctx: StateContext<AppStateModel>, action: AppStateSetProfile) {
    ctx.patchState({profile: action.profile});
  }

  ngxsAfterBootstrap(ctx: StateContext<AppStateModel>) {
  }
}
