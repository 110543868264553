/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AxConfirmGoogleCalendarEventDto } from '../models/ax-confirm-google-calendar-event-dto';
import { AxCreateReservationDto } from '../models/ax-create-reservation-dto';
import { AxFindFreeTimeslotsDto } from '../models/ax-find-free-timeslots-dto';
import { AxSuccessDto } from '../models/ax-success-dto';
import { AxTimeslotsDto } from '../models/ax-timeslots-dto';
import { AxUpdateReservationDto } from '../models/ax-update-reservation-dto';
import { AxVerifyReservationOtpDto } from '../models/ax-verify-reservation-otp-dto';

@Injectable({
  providedIn: 'root',
})
export class AxReservationsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation reservationsControllerFindFreeTimeslots
   */
  static readonly ReservationsControllerFindFreeTimeslotsPath = '/v1/reservations/findFreeTimeslots';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reservationsControllerFindFreeTimeslots()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reservationsControllerFindFreeTimeslots$Response(params: {

    /**
     * This request requires a valid API key.
     */
    'x-api-key'?: string;
    context?: HttpContext
    body: AxFindFreeTimeslotsDto
  }
): Observable<StrictHttpResponse<AxTimeslotsDto>> {

    const rb = new RequestBuilder(this.rootUrl, AxReservationsService.ReservationsControllerFindFreeTimeslotsPath, 'post');
    if (params) {
      rb.header('x-api-key', params['x-api-key'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxTimeslotsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reservationsControllerFindFreeTimeslots$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reservationsControllerFindFreeTimeslots(params: {

    /**
     * This request requires a valid API key.
     */
    'x-api-key'?: string;
    context?: HttpContext
    body: AxFindFreeTimeslotsDto
  }
): Observable<AxTimeslotsDto> {

    return this.reservationsControllerFindFreeTimeslots$Response(params).pipe(
      map((r: StrictHttpResponse<AxTimeslotsDto>) => r.body as AxTimeslotsDto)
    );
  }

  /**
   * Path part for operation reservationsControllerCreate
   */
  static readonly ReservationsControllerCreatePath = '/v1/reservations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reservationsControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reservationsControllerCreate$Response(params: {

    /**
     * This request requires a valid API key.
     */
    'x-api-key'?: string;
    context?: HttpContext
    body: AxCreateReservationDto
  }
): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, AxReservationsService.ReservationsControllerCreatePath, 'post');
    if (params) {
      rb.header('x-api-key', params['x-api-key'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reservationsControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reservationsControllerCreate(params: {

    /**
     * This request requires a valid API key.
     */
    'x-api-key'?: string;
    context?: HttpContext
    body: AxCreateReservationDto
  }
): Observable<{
}> {

    return this.reservationsControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation reservationsControllerRemove
   */
  static readonly ReservationsControllerRemovePath = '/v1/reservations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reservationsControllerRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  reservationsControllerRemove$Response(params: {
    id: number;
    accessKey: string;

    /**
     * This request requires a valid API key.
     */
    'x-api-key'?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AxReservationsService.ReservationsControllerRemovePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('accessKey', params.accessKey, {});
      rb.header('x-api-key', params['x-api-key'], {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reservationsControllerRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reservationsControllerRemove(params: {
    id: number;
    accessKey: string;

    /**
     * This request requires a valid API key.
     */
    'x-api-key'?: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.reservationsControllerRemove$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation reservationsControllerUpdate
   */
  static readonly ReservationsControllerUpdatePath = '/v1/reservations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reservationsControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reservationsControllerUpdate$Response(params: {
    id: number;

    /**
     * This request requires a valid API key.
     */
    'x-api-key'?: string;
    context?: HttpContext
    body: AxUpdateReservationDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AxReservationsService.ReservationsControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.header('x-api-key', params['x-api-key'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reservationsControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reservationsControllerUpdate(params: {
    id: number;

    /**
     * This request requires a valid API key.
     */
    'x-api-key'?: string;
    context?: HttpContext
    body: AxUpdateReservationDto
  }
): Observable<void> {

    return this.reservationsControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation reservationsControllerConfirmGoogleCalendarEventForId
   */
  static readonly ReservationsControllerConfirmGoogleCalendarEventForIdPath = '/v1/reservations/confirmGoogleCalendarEventForId/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reservationsControllerConfirmGoogleCalendarEventForId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reservationsControllerConfirmGoogleCalendarEventForId$Response(params: {
    id: number;
    context?: HttpContext
    body: AxConfirmGoogleCalendarEventDto
  }
): Observable<StrictHttpResponse<AxSuccessDto>> {

    const rb = new RequestBuilder(this.rootUrl, AxReservationsService.ReservationsControllerConfirmGoogleCalendarEventForIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reservationsControllerConfirmGoogleCalendarEventForId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reservationsControllerConfirmGoogleCalendarEventForId(params: {
    id: number;
    context?: HttpContext
    body: AxConfirmGoogleCalendarEventDto
  }
): Observable<AxSuccessDto> {

    return this.reservationsControllerConfirmGoogleCalendarEventForId$Response(params).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>) => r.body as AxSuccessDto)
    );
  }

  /**
   * Path part for operation reservationsControllerVerifyOtp
   */
  static readonly ReservationsControllerVerifyOtpPath = '/v1/reservations/verifyOtp';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reservationsControllerVerifyOtp()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reservationsControllerVerifyOtp$Response(params: {

    /**
     * This request requires a valid API key.
     */
    'x-api-key'?: string;
    context?: HttpContext
    body: AxVerifyReservationOtpDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AxReservationsService.ReservationsControllerVerifyOtpPath, 'post');
    if (params) {
      rb.header('x-api-key', params['x-api-key'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reservationsControllerVerifyOtp$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  reservationsControllerVerifyOtp(params: {

    /**
     * This request requires a valid API key.
     */
    'x-api-key'?: string;
    context?: HttpContext
    body: AxVerifyReservationOtpDto
  }
): Observable<boolean> {

    return this.reservationsControllerVerifyOtp$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
