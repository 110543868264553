import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RelativeDateTimePipe } from './relative-date-time.pipe';


@NgModule({
  declarations: [RelativeDateTimePipe],
  imports: [
    CommonModule,
  ],
  exports: [RelativeDateTimePipe],
})
export class RelativeDateTimeModule {
}
