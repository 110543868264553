import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { AppInitializer } from 'src/app/app.initializer';
import { AxCommonsApiModule } from 'src/app/ax-commons-api/ax-commons-api.module';
import { AppState } from 'src/app/ngxs/app/app.state';
import { environment } from 'src/environments/environment';
import { VexModule } from '../@vex/vex.module';
import { ApiModule } from './_generated/axova-rest-api';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { MatSnackBarModule } from '@angular/material/snack-bar';

export const initializeApp = (appInitializer: AppInitializer) => (): Promise<any> => appInitializer.init();

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,

    // Axova
    AxCommonsApiModule,
    ApiModule.forRoot({ rootUrl: environment.apiConfiguration.baseUrl }),
    NgxsModule.forRoot([
      AppState,
    ], {
      developmentMode: !environment.production,
    }),
    NgxsStoragePluginModule.forRoot({
      storage: StorageOption.LocalStorage,
    }),
    NgxsResetPluginModule.forRoot(),
    NgxDropzoneModule,
    MatSnackBarModule,

    // Vex
    VexModule,
    CustomLayoutModule,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'de-CH',
    },
    AppInitializer,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppInitializer],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
