import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({name: 'getUpload'})
export class AxApiGetUploadPipe implements PipeTransform {
  transform(uploadId: number) {
    if (!uploadId) {
      return '';
    }
    return `${environment.apiConfiguration.baseUrl}/v1/uploads/${uploadId}`;
  }
}
