import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AxApiBaseService } from 'src/app/ax-commons-api/ax-api-base.service';
import { AxApiGetUploadPipe } from 'src/app/ax-commons-api/ax-api-get-upload.pipe';
import { AxApiJsonInterceptor } from 'src/app/ax-commons-api/ax-api-json.interceptor';
import { AxApiProfileimagePipe } from 'src/app/ax-commons-api/ax-api-profileimage.pipe';
import { AxApiTokenInterceptor } from './ax-api-token.interceptor';

@NgModule({
  providers: [
    AxApiBaseService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AxApiJsonInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AxApiTokenInterceptor,
      multi: true,
    },
  ],
  declarations: [
    AxApiProfileimagePipe,
    AxApiGetUploadPipe,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  exports: [
    AxApiProfileimagePipe,
    AxApiGetUploadPipe,
  ],
})
export class AxCommonsApiModule {
}
