import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { VexRoutes } from 'src/@vex/interfaces/vex-route.interface';
import { AxIsLoggedInGuard } from 'src/app/ax-commons-api/guards/ax-is-logged-in-guard.service';
import { AxRedirectIfLoggedInGuard } from 'src/app/ax-commons-api/guards/ax-redirect-if-logged-in-guard.service';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';

const routes: VexRoutes = [
  {
    path: 'auth',
    canActivate: [AxRedirectIfLoggedInGuard],
    children: [
      {
        path: 'login',
        loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginModule),
      },
    ],
  },
  {
    path: '',
    component: CustomLayoutComponent,
    canActivate: [AxIsLoggedInGuard],
    children: [
      {
        path: '',
        redirectTo: 'news',
        pathMatch: 'full',
      },
      {
        path: 'profile',
        loadChildren: () => import('src/app/pages/profile/profile.module').then(m => m.ProfileModule),
      },
      {
        path: 'news',
        loadChildren: () => import('src/app/pages/news/news.module').then(m => m.NewsModule),
      },
      {
        path: 'audiences',
        loadChildren: () => import('src/app/pages/audiences/audiences.module').then(m => m.AudiencesModule),
      },
      {
        path: 'users',
        loadChildren: () => import('src/app/pages/users/users.module').then(m => m.UsersModule),
      },
      {
        path: 'pages',
        children: [
          {
            path: 'error-404',
            loadChildren: () => import('./pages/errors/error-404/error-404.module').then(m => m.Error404Module),
          },
          {
            path: 'error-500',
            loadChildren: () => import('./pages/errors/error-500/error-500.module').then(m => m.Error500Module),
          },
        ],
      },
      {
        path: '**',
        loadChildren: () => import('./pages/errors/error-404/error-404.module').then(m => m.Error404Module),
      },
    ],
  },
  {
    path: '**',
    loadChildren: () => import('./pages/errors/error-404/error-404.module').then(m => m.Error404Module),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled',
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
