import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { LoggerService } from 'src/app/ax-commons-logger/logger/logger.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AxApiBaseService {
  protected readonly baseUrl: string;
  protected readonly headers = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(
    protected httpClient: HttpClient,
  ) {
    this.baseUrl = environment.apiConfiguration.baseUrl;
  }

  /**
   * Handle HTTP exceptions.
   *
   * @param error
   * @protected
   */
  protected handleHttpErrorResponse(error: HttpErrorResponse) {
    LoggerService.LOG(this, 'handleHttpException()', error);
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = error.error.message;
    } else {
      // server-side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(msg);
  }
}
