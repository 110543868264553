/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AxAuthDto } from '../models/ax-auth-dto';
import { AxGetMailsAndPhonesDto } from '../models/ax-get-mails-and-phones-dto';
import { AxMailsAndPhonesDto } from '../models/ax-mails-and-phones-dto';
import { AxRefreshtokenDto } from '../models/ax-refreshtoken-dto';
import { AxRequestOtpDto } from '../models/ax-request-otp-dto';
import { AxSuccessDto } from '../models/ax-success-dto';
import { AxTokenResponseDto } from '../models/ax-token-response-dto';
import { AxVerifyOtpDto } from '../models/ax-verify-otp-dto';

@Injectable({
  providedIn: 'root',
})
export class AxAuthService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation authControllerLogin
   */
  static readonly AuthControllerLoginPath = '/v1/auth/loginWithUsernameAndPassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerLogin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerLogin$Response(params: {
    context?: HttpContext
    body: AxAuthDto
  }
): Observable<StrictHttpResponse<AxTokenResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, AxAuthService.AuthControllerLoginPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxTokenResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authControllerLogin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerLogin(params: {
    context?: HttpContext
    body: AxAuthDto
  }
): Observable<AxTokenResponseDto> {

    return this.authControllerLogin$Response(params).pipe(
      map((r: StrictHttpResponse<AxTokenResponseDto>) => r.body as AxTokenResponseDto)
    );
  }

  /**
   * Path part for operation authControllerRefreshToken
   */
  static readonly AuthControllerRefreshTokenPath = '/v1/auth/refreshTokens';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerRefreshToken()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerRefreshToken$Response(params: {
    context?: HttpContext
    body: AxRefreshtokenDto
  }
): Observable<StrictHttpResponse<AxTokenResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, AxAuthService.AuthControllerRefreshTokenPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxTokenResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authControllerRefreshToken$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerRefreshToken(params: {
    context?: HttpContext
    body: AxRefreshtokenDto
  }
): Observable<AxTokenResponseDto> {

    return this.authControllerRefreshToken$Response(params).pipe(
      map((r: StrictHttpResponse<AxTokenResponseDto>) => r.body as AxTokenResponseDto)
    );
  }

  /**
   * Path part for operation authControllerLogout
   */
  static readonly AuthControllerLogoutPath = '/v1/auth/logout';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerLogout()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerLogout$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AxAuthService.AuthControllerLogoutPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authControllerLogout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authControllerLogout(params?: {
    context?: HttpContext
  }
): Observable<void> {

    return this.authControllerLogout$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation authControllerGetMailsAndPhones
   */
  static readonly AuthControllerGetMailsAndPhonesPath = '/v1/auth/getMailsAndPhones';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerGetMailsAndPhones()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerGetMailsAndPhones$Response(params: {
    context?: HttpContext
    body: AxGetMailsAndPhonesDto
  }
): Observable<StrictHttpResponse<AxMailsAndPhonesDto>> {

    const rb = new RequestBuilder(this.rootUrl, AxAuthService.AuthControllerGetMailsAndPhonesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxMailsAndPhonesDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authControllerGetMailsAndPhones$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerGetMailsAndPhones(params: {
    context?: HttpContext
    body: AxGetMailsAndPhonesDto
  }
): Observable<AxMailsAndPhonesDto> {

    return this.authControllerGetMailsAndPhones$Response(params).pipe(
      map((r: StrictHttpResponse<AxMailsAndPhonesDto>) => r.body as AxMailsAndPhonesDto)
    );
  }

  /**
   * Path part for operation authControllerGetMailsAndPhonesForContact
   */
  static readonly AuthControllerGetMailsAndPhonesForContactPath = '/v1/auth/getMailsAndPhonesForContact';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerGetMailsAndPhonesForContact()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerGetMailsAndPhonesForContact$Response(params: {
    context?: HttpContext
    body: AxGetMailsAndPhonesDto
  }
): Observable<StrictHttpResponse<AxMailsAndPhonesDto>> {

    const rb = new RequestBuilder(this.rootUrl, AxAuthService.AuthControllerGetMailsAndPhonesForContactPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxMailsAndPhonesDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authControllerGetMailsAndPhonesForContact$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerGetMailsAndPhonesForContact(params: {
    context?: HttpContext
    body: AxGetMailsAndPhonesDto
  }
): Observable<AxMailsAndPhonesDto> {

    return this.authControllerGetMailsAndPhonesForContact$Response(params).pipe(
      map((r: StrictHttpResponse<AxMailsAndPhonesDto>) => r.body as AxMailsAndPhonesDto)
    );
  }

  /**
   * Path part for operation authControllerRequestOtp
   */
  static readonly AuthControllerRequestOtpPath = '/v1/auth/requestOtp';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerRequestOtp()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerRequestOtp$Response(params: {
    context?: HttpContext
    body: AxRequestOtpDto
  }
): Observable<StrictHttpResponse<AxSuccessDto>> {

    const rb = new RequestBuilder(this.rootUrl, AxAuthService.AuthControllerRequestOtpPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authControllerRequestOtp$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerRequestOtp(params: {
    context?: HttpContext
    body: AxRequestOtpDto
  }
): Observable<AxSuccessDto> {

    return this.authControllerRequestOtp$Response(params).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>) => r.body as AxSuccessDto)
    );
  }

  /**
   * Path part for operation authControllerVerifyOtp
   */
  static readonly AuthControllerVerifyOtpPath = '/v1/auth/verifyOtp';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerVerifyOtp()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerVerifyOtp$Response(params: {
    context?: HttpContext
    body: AxVerifyOtpDto
  }
): Observable<StrictHttpResponse<AxTokenResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, AxAuthService.AuthControllerVerifyOtpPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxTokenResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `authControllerVerifyOtp$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authControllerVerifyOtp(params: {
    context?: HttpContext
    body: AxVerifyOtpDto
  }
): Observable<AxTokenResponseDto> {

    return this.authControllerVerifyOtp$Response(params).pipe(
      map((r: StrictHttpResponse<AxTokenResponseDto>) => r.body as AxTokenResponseDto)
    );
  }

}
