/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AxCalculationitem } from '../models/ax-calculationitem';
import { AxDocument } from '../models/ax-document';
import { AxItemsForProjectDto } from '../models/ax-items-for-project-dto';
import { AxPaginatedResultDto } from '../models/ax-paginated-result-dto';
import { AxProject } from '../models/ax-project';
import { AxTask } from '../models/ax-task';

@Injectable({
  providedIn: 'root',
})
export class AxProjectsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation projectsControllerFindAll
   */
  static readonly ProjectsControllerFindAllPath = '/v1/projects';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerFindAll$Response(params?: {
    page?: any;
    limit?: any;
    sortBy?: any;
    searchBy?: any;
    search?: any;
    filter?: any;
    path?: any;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AxPaginatedResultDto>> {

    const rb = new RequestBuilder(this.rootUrl, AxProjectsService.ProjectsControllerFindAllPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('searchBy', params.searchBy, {});
      rb.query('search', params.search, {});
      rb.query('filter', params.filter, {});
      rb.query('path', params.path, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxPaginatedResultDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectsControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerFindAll(params?: {
    page?: any;
    limit?: any;
    sortBy?: any;
    searchBy?: any;
    search?: any;
    filter?: any;
    path?: any;
    context?: HttpContext
  }
): Observable<AxPaginatedResultDto> {

    return this.projectsControllerFindAll$Response(params).pipe(
      map((r: StrictHttpResponse<AxPaginatedResultDto>) => r.body as AxPaginatedResultDto)
    );
  }

  /**
   * Path part for operation projectsControllerFindAllPublic
   */
  static readonly ProjectsControllerFindAllPublicPath = '/v1/projects/public';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerFindAllPublic()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerFindAllPublic$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<{
}>>> {

    const rb = new RequestBuilder(this.rootUrl, AxProjectsService.ProjectsControllerFindAllPublicPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<{
        }>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectsControllerFindAllPublic$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerFindAllPublic(params?: {
    context?: HttpContext
  }
): Observable<Array<{
}>> {

    return this.projectsControllerFindAllPublic$Response(params).pipe(
      map((r: StrictHttpResponse<Array<{
}>>) => r.body as Array<{
}>)
    );
  }

  /**
   * Path part for operation projectsControllerFindOne
   */
  static readonly ProjectsControllerFindOnePath = '/v1/projects/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerFindOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerFindOne$Response(params: {
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AxProject>> {

    const rb = new RequestBuilder(this.rootUrl, AxProjectsService.ProjectsControllerFindOnePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxProject>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectsControllerFindOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerFindOne(params: {
    id: number;
    context?: HttpContext
  }
): Observable<AxProject> {

    return this.projectsControllerFindOne$Response(params).pipe(
      map((r: StrictHttpResponse<AxProject>) => r.body as AxProject)
    );
  }

  /**
   * Path part for operation projectsControllerGetItemsForProject
   */
  static readonly ProjectsControllerGetItemsForProjectPath = '/v1/projects/{id}/items';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerGetItemsForProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGetItemsForProject$Response(params: {
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AxItemsForProjectDto>> {

    const rb = new RequestBuilder(this.rootUrl, AxProjectsService.ProjectsControllerGetItemsForProjectPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxItemsForProjectDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectsControllerGetItemsForProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGetItemsForProject(params: {
    id: number;
    context?: HttpContext
  }
): Observable<AxItemsForProjectDto> {

    return this.projectsControllerGetItemsForProject$Response(params).pipe(
      map((r: StrictHttpResponse<AxItemsForProjectDto>) => r.body as AxItemsForProjectDto)
    );
  }

  /**
   * Path part for operation projectsControllerGetCalculationitemsForProject
   */
  static readonly ProjectsControllerGetCalculationitemsForProjectPath = '/v1/projects/{id}/calculationitems';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerGetCalculationitemsForProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGetCalculationitemsForProject$Response(params: {
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AxCalculationitem>>> {

    const rb = new RequestBuilder(this.rootUrl, AxProjectsService.ProjectsControllerGetCalculationitemsForProjectPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxCalculationitem>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectsControllerGetCalculationitemsForProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGetCalculationitemsForProject(params: {
    id: number;
    context?: HttpContext
  }
): Observable<Array<AxCalculationitem>> {

    return this.projectsControllerGetCalculationitemsForProject$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AxCalculationitem>>) => r.body as Array<AxCalculationitem>)
    );
  }

  /**
   * Path part for operation projectsControllerGetTasksForProject
   */
  static readonly ProjectsControllerGetTasksForProjectPath = '/v1/projects/{id}/tasks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerGetTasksForProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGetTasksForProject$Response(params: {
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AxTask>>> {

    const rb = new RequestBuilder(this.rootUrl, AxProjectsService.ProjectsControllerGetTasksForProjectPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxTask>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectsControllerGetTasksForProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGetTasksForProject(params: {
    id: number;
    context?: HttpContext
  }
): Observable<Array<AxTask>> {

    return this.projectsControllerGetTasksForProject$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AxTask>>) => r.body as Array<AxTask>)
    );
  }

  /**
   * Path part for operation projectsControllerGetBaustellenDispoTasksForProject
   */
  static readonly ProjectsControllerGetBaustellenDispoTasksForProjectPath = '/v1/projects/{id}/tasks/baustellendispo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerGetBaustellenDispoTasksForProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGetBaustellenDispoTasksForProject$Response(params: {
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AxTask>>> {

    const rb = new RequestBuilder(this.rootUrl, AxProjectsService.ProjectsControllerGetBaustellenDispoTasksForProjectPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxTask>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectsControllerGetBaustellenDispoTasksForProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGetBaustellenDispoTasksForProject(params: {
    id: number;
    context?: HttpContext
  }
): Observable<Array<AxTask>> {

    return this.projectsControllerGetBaustellenDispoTasksForProject$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AxTask>>) => r.body as Array<AxTask>)
    );
  }

  /**
   * Path part for operation projectsControllerGetAppointmentsForProject
   */
  static readonly ProjectsControllerGetAppointmentsForProjectPath = '/v1/projects/{id}/appointments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerGetAppointmentsForProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGetAppointmentsForProject$Response(params: {
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AxTask>>> {

    const rb = new RequestBuilder(this.rootUrl, AxProjectsService.ProjectsControllerGetAppointmentsForProjectPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxTask>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectsControllerGetAppointmentsForProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGetAppointmentsForProject(params: {
    id: number;
    context?: HttpContext
  }
): Observable<Array<AxTask>> {

    return this.projectsControllerGetAppointmentsForProject$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AxTask>>) => r.body as Array<AxTask>)
    );
  }

  /**
   * Path part for operation projectsControllerGetDocumentsForProject
   */
  static readonly ProjectsControllerGetDocumentsForProjectPath = '/v1/projects/{id}/documents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectsControllerGetDocumentsForProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGetDocumentsForProject$Response(params: {
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AxDocument>>> {

    const rb = new RequestBuilder(this.rootUrl, AxProjectsService.ProjectsControllerGetDocumentsForProjectPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxDocument>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `projectsControllerGetDocumentsForProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectsControllerGetDocumentsForProject(params: {
    id: number;
    context?: HttpContext
  }
): Observable<Array<AxDocument>> {

    return this.projectsControllerGetDocumentsForProject$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AxDocument>>) => r.body as Array<AxDocument>)
    );
  }

}
