/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AxAbsence } from '../models/ax-absence';
import { AxAxProfileDto } from '../models/ax-ax-profile-dto';
import { AxContainer } from '../models/ax-container';
import { AxDocument } from '../models/ax-document';
import { AxEvent } from '../models/ax-event';
import { AxEventInvitation } from '../models/ax-event-invitation';
import { AxItem } from '../models/ax-item';
import { AxItemsForProjectDto } from '../models/ax-items-for-project-dto';
import { AxNews } from '../models/ax-news';
import { AxPaginatedResultDto } from '../models/ax-paginated-result-dto';
import { AxProjectrevenue } from '../models/ax-projectrevenue';
import { AxTask } from '../models/ax-task';
import { AxTasksForProjectDto } from '../models/ax-tasks-for-project-dto';
import { AxUpdateEventInvitationDto } from '../models/ax-update-event-invitation-dto';
import { AxUpdateProfileDto } from '../models/ax-update-profile-dto';
import { AxUser } from '../models/ax-user';
import { AxUsertraining } from '../models/ax-usertraining';

@Injectable({
  providedIn: 'root',
})
export class AxMyService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation myProjectsControllerGetProjectsByContainer
   */
  static readonly MyProjectsControllerGetProjectsByContainerPath = '/v1/my/projects';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `myProjectsControllerGetProjectsByContainer()` instead.
   *
   * This method doesn't expect any request body.
   */
  myProjectsControllerGetProjectsByContainer$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AxContainer>>> {

    const rb = new RequestBuilder(this.rootUrl, AxMyService.MyProjectsControllerGetProjectsByContainerPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxContainer>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `myProjectsControllerGetProjectsByContainer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  myProjectsControllerGetProjectsByContainer(params?: {
    context?: HttpContext
  }
): Observable<Array<AxContainer>> {

    return this.myProjectsControllerGetProjectsByContainer$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AxContainer>>) => r.body as Array<AxContainer>)
    );
  }

  /**
   * Path part for operation myProjectsControllerGetItemsForProject
   */
  static readonly MyProjectsControllerGetItemsForProjectPath = '/v1/my/projects/{id}/items';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `myProjectsControllerGetItemsForProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  myProjectsControllerGetItemsForProject$Response(params: {
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AxItemsForProjectDto>> {

    const rb = new RequestBuilder(this.rootUrl, AxMyService.MyProjectsControllerGetItemsForProjectPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxItemsForProjectDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `myProjectsControllerGetItemsForProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  myProjectsControllerGetItemsForProject(params: {
    id: number;
    context?: HttpContext
  }
): Observable<AxItemsForProjectDto> {

    return this.myProjectsControllerGetItemsForProject$Response(params).pipe(
      map((r: StrictHttpResponse<AxItemsForProjectDto>) => r.body as AxItemsForProjectDto)
    );
  }

  /**
   * Path part for operation myProjectsControllerGetItemByCalculationitemIdForProject
   */
  static readonly MyProjectsControllerGetItemByCalculationitemIdForProjectPath = '/v1/my/projects/{id}/itemByCalculationId/{calculationitemId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `myProjectsControllerGetItemByCalculationitemIdForProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  myProjectsControllerGetItemByCalculationitemIdForProject$Response(params: {
    id: number;
    calculationitemId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AxItem>> {

    const rb = new RequestBuilder(this.rootUrl, AxMyService.MyProjectsControllerGetItemByCalculationitemIdForProjectPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('calculationitemId', params.calculationitemId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxItem>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `myProjectsControllerGetItemByCalculationitemIdForProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  myProjectsControllerGetItemByCalculationitemIdForProject(params: {
    id: number;
    calculationitemId: number;
    context?: HttpContext
  }
): Observable<AxItem> {

    return this.myProjectsControllerGetItemByCalculationitemIdForProject$Response(params).pipe(
      map((r: StrictHttpResponse<AxItem>) => r.body as AxItem)
    );
  }

  /**
   * Path part for operation myProjectsControllerGetTasksForProject
   */
  static readonly MyProjectsControllerGetTasksForProjectPath = '/v1/my/projects/{id}/tasks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `myProjectsControllerGetTasksForProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  myProjectsControllerGetTasksForProject$Response(params: {
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AxTasksForProjectDto>> {

    const rb = new RequestBuilder(this.rootUrl, AxMyService.MyProjectsControllerGetTasksForProjectPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxTasksForProjectDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `myProjectsControllerGetTasksForProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  myProjectsControllerGetTasksForProject(params: {
    id: number;
    context?: HttpContext
  }
): Observable<AxTasksForProjectDto> {

    return this.myProjectsControllerGetTasksForProject$Response(params).pipe(
      map((r: StrictHttpResponse<AxTasksForProjectDto>) => r.body as AxTasksForProjectDto)
    );
  }

  /**
   * Path part for operation myProjectsControllerGetTaskForProject
   */
  static readonly MyProjectsControllerGetTaskForProjectPath = '/v1/my/projects/{projectId}/tasks/{taskId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `myProjectsControllerGetTaskForProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  myProjectsControllerGetTaskForProject$Response(params: {
    projectId: number;
    taskId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AxTask>> {

    const rb = new RequestBuilder(this.rootUrl, AxMyService.MyProjectsControllerGetTaskForProjectPath, 'get');
    if (params) {
      rb.path('projectId', params.projectId, {});
      rb.path('taskId', params.taskId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxTask>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `myProjectsControllerGetTaskForProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  myProjectsControllerGetTaskForProject(params: {
    projectId: number;
    taskId: number;
    context?: HttpContext
  }
): Observable<AxTask> {

    return this.myProjectsControllerGetTaskForProject$Response(params).pipe(
      map((r: StrictHttpResponse<AxTask>) => r.body as AxTask)
    );
  }

  /**
   * Path part for operation myProjectsControllerGetDocumentsForTask
   */
  static readonly MyProjectsControllerGetDocumentsForTaskPath = '/v1/my/projects/{projectId}/documentsForTask/{taskId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `myProjectsControllerGetDocumentsForTask()` instead.
   *
   * This method doesn't expect any request body.
   */
  myProjectsControllerGetDocumentsForTask$Response(params: {
    projectId: number;
    taskId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AxDocument>>> {

    const rb = new RequestBuilder(this.rootUrl, AxMyService.MyProjectsControllerGetDocumentsForTaskPath, 'get');
    if (params) {
      rb.path('projectId', params.projectId, {});
      rb.path('taskId', params.taskId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxDocument>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `myProjectsControllerGetDocumentsForTask$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  myProjectsControllerGetDocumentsForTask(params: {
    projectId: number;
    taskId: number;
    context?: HttpContext
  }
): Observable<Array<AxDocument>> {

    return this.myProjectsControllerGetDocumentsForTask$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AxDocument>>) => r.body as Array<AxDocument>)
    );
  }

  /**
   * Path part for operation myProjectsControllerGetAppointmentsByProject
   */
  static readonly MyProjectsControllerGetAppointmentsByProjectPath = '/v1/my/projects/{id}/appointments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `myProjectsControllerGetAppointmentsByProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  myProjectsControllerGetAppointmentsByProject$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AxTask>>> {

    const rb = new RequestBuilder(this.rootUrl, AxMyService.MyProjectsControllerGetAppointmentsByProjectPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxTask>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `myProjectsControllerGetAppointmentsByProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  myProjectsControllerGetAppointmentsByProject(params: {
    id: string;
    context?: HttpContext
  }
): Observable<Array<AxTask>> {

    return this.myProjectsControllerGetAppointmentsByProject$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AxTask>>) => r.body as Array<AxTask>)
    );
  }

  /**
   * Path part for operation myProjectsControllerGetProjectrevenuesForProject
   */
  static readonly MyProjectsControllerGetProjectrevenuesForProjectPath = '/v1/my/projects/{id}/projectrevenues';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `myProjectsControllerGetProjectrevenuesForProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  myProjectsControllerGetProjectrevenuesForProject$Response(params: {
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AxProjectrevenue>>> {

    const rb = new RequestBuilder(this.rootUrl, AxMyService.MyProjectsControllerGetProjectrevenuesForProjectPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxProjectrevenue>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `myProjectsControllerGetProjectrevenuesForProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  myProjectsControllerGetProjectrevenuesForProject(params: {
    id: number;
    context?: HttpContext
  }
): Observable<Array<AxProjectrevenue>> {

    return this.myProjectsControllerGetProjectrevenuesForProject$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AxProjectrevenue>>) => r.body as Array<AxProjectrevenue>)
    );
  }

  /**
   * Path part for operation myContactsControllerContactPersons
   */
  static readonly MyContactsControllerContactPersonsPath = '/v1/my/contacts/contactPersons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `myContactsControllerContactPersons()` instead.
   *
   * This method doesn't expect any request body.
   */
  myContactsControllerContactPersons$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AxUser>>> {

    const rb = new RequestBuilder(this.rootUrl, AxMyService.MyContactsControllerContactPersonsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxUser>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `myContactsControllerContactPersons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  myContactsControllerContactPersons(params?: {
    context?: HttpContext
  }
): Observable<Array<AxUser>> {

    return this.myContactsControllerContactPersons$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AxUser>>) => r.body as Array<AxUser>)
    );
  }

  /**
   * Path part for operation myNewsControllerFindAllNewsPaginated
   */
  static readonly MyNewsControllerFindAllNewsPaginatedPath = '/v1/my/news';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `myNewsControllerFindAllNewsPaginated()` instead.
   *
   * This method doesn't expect any request body.
   */
  myNewsControllerFindAllNewsPaginated$Response(params?: {
    page?: any;
    limit?: any;
    sortBy?: any;
    searchBy?: any;
    search?: any;
    filter?: any;
    path?: any;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AxPaginatedResultDto>> {

    const rb = new RequestBuilder(this.rootUrl, AxMyService.MyNewsControllerFindAllNewsPaginatedPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('sortBy', params.sortBy, {});
      rb.query('searchBy', params.searchBy, {});
      rb.query('search', params.search, {});
      rb.query('filter', params.filter, {});
      rb.query('path', params.path, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxPaginatedResultDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `myNewsControllerFindAllNewsPaginated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  myNewsControllerFindAllNewsPaginated(params?: {
    page?: any;
    limit?: any;
    sortBy?: any;
    searchBy?: any;
    search?: any;
    filter?: any;
    path?: any;
    context?: HttpContext
  }
): Observable<AxPaginatedResultDto> {

    return this.myNewsControllerFindAllNewsPaginated$Response(params).pipe(
      map((r: StrictHttpResponse<AxPaginatedResultDto>) => r.body as AxPaginatedResultDto)
    );
  }

  /**
   * Path part for operation myNewsControllerFindOneNews
   */
  static readonly MyNewsControllerFindOneNewsPath = '/v1/my/news/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `myNewsControllerFindOneNews()` instead.
   *
   * This method doesn't expect any request body.
   */
  myNewsControllerFindOneNews$Response(params: {
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AxNews>> {

    const rb = new RequestBuilder(this.rootUrl, AxMyService.MyNewsControllerFindOneNewsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxNews>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `myNewsControllerFindOneNews$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  myNewsControllerFindOneNews(params: {
    id: number;
    context?: HttpContext
  }
): Observable<AxNews> {

    return this.myNewsControllerFindOneNews$Response(params).pipe(
      map((r: StrictHttpResponse<AxNews>) => r.body as AxNews)
    );
  }

  /**
   * Path part for operation myProfileControllerProfile
   */
  static readonly MyProfileControllerProfilePath = '/v1/my/profile/profile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `myProfileControllerProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  myProfileControllerProfile$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AxAxProfileDto>> {

    const rb = new RequestBuilder(this.rootUrl, AxMyService.MyProfileControllerProfilePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxAxProfileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `myProfileControllerProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  myProfileControllerProfile(params?: {
    context?: HttpContext
  }
): Observable<AxAxProfileDto> {

    return this.myProfileControllerProfile$Response(params).pipe(
      map((r: StrictHttpResponse<AxAxProfileDto>) => r.body as AxAxProfileDto)
    );
  }

  /**
   * Path part for operation myProfileControllerUpdate
   */
  static readonly MyProfileControllerUpdatePath = '/v1/my/profile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `myProfileControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  myProfileControllerUpdate$Response(params: {
    context?: HttpContext
    body: AxUpdateProfileDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, AxMyService.MyProfileControllerUpdatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `myProfileControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  myProfileControllerUpdate(params: {
    context?: HttpContext
    body: AxUpdateProfileDto
  }
): Observable<boolean> {

    return this.myProfileControllerUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation myAbsencesControllerGetMyAbsences
   */
  static readonly MyAbsencesControllerGetMyAbsencesPath = '/v1/my/absences';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `myAbsencesControllerGetMyAbsences()` instead.
   *
   * This method doesn't expect any request body.
   */
  myAbsencesControllerGetMyAbsences$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AxAbsence>>> {

    const rb = new RequestBuilder(this.rootUrl, AxMyService.MyAbsencesControllerGetMyAbsencesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxAbsence>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `myAbsencesControllerGetMyAbsences$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  myAbsencesControllerGetMyAbsences(params?: {
    context?: HttpContext
  }
): Observable<Array<AxAbsence>> {

    return this.myAbsencesControllerGetMyAbsences$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AxAbsence>>) => r.body as Array<AxAbsence>)
    );
  }

  /**
   * Path part for operation myAbsencesControllerGetMyApprovals
   */
  static readonly MyAbsencesControllerGetMyApprovalsPath = '/v1/my/absences/approvals';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `myAbsencesControllerGetMyApprovals()` instead.
   *
   * This method doesn't expect any request body.
   */
  myAbsencesControllerGetMyApprovals$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AxAbsence>>> {

    const rb = new RequestBuilder(this.rootUrl, AxMyService.MyAbsencesControllerGetMyApprovalsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxAbsence>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `myAbsencesControllerGetMyApprovals$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  myAbsencesControllerGetMyApprovals(params?: {
    context?: HttpContext
  }
): Observable<Array<AxAbsence>> {

    return this.myAbsencesControllerGetMyApprovals$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AxAbsence>>) => r.body as Array<AxAbsence>)
    );
  }

  /**
   * Path part for operation myUsertrainingsControllerGetMyTrainings
   */
  static readonly MyUsertrainingsControllerGetMyTrainingsPath = '/v1/my/trainings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `myUsertrainingsControllerGetMyTrainings()` instead.
   *
   * This method doesn't expect any request body.
   */
  myUsertrainingsControllerGetMyTrainings$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AxUsertraining>>> {

    const rb = new RequestBuilder(this.rootUrl, AxMyService.MyUsertrainingsControllerGetMyTrainingsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxUsertraining>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `myUsertrainingsControllerGetMyTrainings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  myUsertrainingsControllerGetMyTrainings(params?: {
    context?: HttpContext
  }
): Observable<Array<AxUsertraining>> {

    return this.myUsertrainingsControllerGetMyTrainings$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AxUsertraining>>) => r.body as Array<AxUsertraining>)
    );
  }

  /**
   * Path part for operation myEventsControllerFindAll
   */
  static readonly MyEventsControllerFindAllPath = '/v1/my/events';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `myEventsControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  myEventsControllerFindAll$Response(params?: {
    onlyFutureEvents?: any;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<AxEvent>>> {

    const rb = new RequestBuilder(this.rootUrl, AxMyService.MyEventsControllerFindAllPath, 'get');
    if (params) {
      rb.query('onlyFutureEvents', params.onlyFutureEvents, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxEvent>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `myEventsControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  myEventsControllerFindAll(params?: {
    onlyFutureEvents?: any;
    context?: HttpContext
  }
): Observable<Array<AxEvent>> {

    return this.myEventsControllerFindAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AxEvent>>) => r.body as Array<AxEvent>)
    );
  }

  /**
   * Path part for operation myEventsControllerFindById
   */
  static readonly MyEventsControllerFindByIdPath = '/v1/my/events/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `myEventsControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  myEventsControllerFindById$Response(params: {
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AxEvent>> {

    const rb = new RequestBuilder(this.rootUrl, AxMyService.MyEventsControllerFindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxEvent>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `myEventsControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  myEventsControllerFindById(params: {
    id: number;
    context?: HttpContext
  }
): Observable<AxEvent> {

    return this.myEventsControllerFindById$Response(params).pipe(
      map((r: StrictHttpResponse<AxEvent>) => r.body as AxEvent)
    );
  }

  /**
   * Path part for operation myEventsControllerFindInvitationByEventId
   */
  static readonly MyEventsControllerFindInvitationByEventIdPath = '/v1/my/events/{id}/invitation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `myEventsControllerFindInvitationByEventId()` instead.
   *
   * This method doesn't expect any request body.
   */
  myEventsControllerFindInvitationByEventId$Response(params: {
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AxEventInvitation>> {

    const rb = new RequestBuilder(this.rootUrl, AxMyService.MyEventsControllerFindInvitationByEventIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxEventInvitation>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `myEventsControllerFindInvitationByEventId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  myEventsControllerFindInvitationByEventId(params: {
    id: number;
    context?: HttpContext
  }
): Observable<AxEventInvitation> {

    return this.myEventsControllerFindInvitationByEventId$Response(params).pipe(
      map((r: StrictHttpResponse<AxEventInvitation>) => r.body as AxEventInvitation)
    );
  }

  /**
   * Path part for operation myEventsControllerAccept
   */
  static readonly MyEventsControllerAcceptPath = '/v1/my/events/{id}/invitation/{invitationId}/accept';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `myEventsControllerAccept()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  myEventsControllerAccept$Response(params: {
    invitationId: number;
    context?: HttpContext
    body: AxUpdateEventInvitationDto
  }
): Observable<StrictHttpResponse<Array<{
}>>> {

    const rb = new RequestBuilder(this.rootUrl, AxMyService.MyEventsControllerAcceptPath, 'patch');
    if (params) {
      rb.path('invitationId', params.invitationId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<{
        }>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `myEventsControllerAccept$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  myEventsControllerAccept(params: {
    invitationId: number;
    context?: HttpContext
    body: AxUpdateEventInvitationDto
  }
): Observable<Array<{
}>> {

    return this.myEventsControllerAccept$Response(params).pipe(
      map((r: StrictHttpResponse<Array<{
}>>) => r.body as Array<{
}>)
    );
  }

  /**
   * Path part for operation myEventsControllerDecline
   */
  static readonly MyEventsControllerDeclinePath = '/v1/my/events/{id}/invitation/{invitationId}/decline';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `myEventsControllerDecline()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  myEventsControllerDecline$Response(params: {
    invitationId: number;
    context?: HttpContext
    body: AxUpdateEventInvitationDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AxMyService.MyEventsControllerDeclinePath, 'patch');
    if (params) {
      rb.path('invitationId', params.invitationId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `myEventsControllerDecline$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  myEventsControllerDecline(params: {
    invitationId: number;
    context?: HttpContext
    body: AxUpdateEventInvitationDto
  }
): Observable<void> {

    return this.myEventsControllerDecline$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation myEventsControllerAcceptWithAccessKey
   */
  static readonly MyEventsControllerAcceptWithAccessKeyPath = '/v1/my/events/{id}/invitation/{invitationId}/acceptWithAccessKey';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `myEventsControllerAcceptWithAccessKey()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  myEventsControllerAcceptWithAccessKey$Response(params: {
    invitationId: number;
    accessKey?: string;
    context?: HttpContext
    body: AxUpdateEventInvitationDto
  }
): Observable<StrictHttpResponse<Array<{
}>>> {

    const rb = new RequestBuilder(this.rootUrl, AxMyService.MyEventsControllerAcceptWithAccessKeyPath, 'patch');
    if (params) {
      rb.path('invitationId', params.invitationId, {});
      rb.query('accessKey', params.accessKey, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<{
        }>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `myEventsControllerAcceptWithAccessKey$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  myEventsControllerAcceptWithAccessKey(params: {
    invitationId: number;
    accessKey?: string;
    context?: HttpContext
    body: AxUpdateEventInvitationDto
  }
): Observable<Array<{
}>> {

    return this.myEventsControllerAcceptWithAccessKey$Response(params).pipe(
      map((r: StrictHttpResponse<Array<{
}>>) => r.body as Array<{
}>)
    );
  }

  /**
   * Path part for operation myEventsControllerDeclineWithAccessKey
   */
  static readonly MyEventsControllerDeclineWithAccessKeyPath = '/v1/my/events/{id}/invitation/{invitationId}/declineWithAccessKey';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `myEventsControllerDeclineWithAccessKey()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  myEventsControllerDeclineWithAccessKey$Response(params: {
    invitationId: number;
    accessKey?: string;
    context?: HttpContext
    body: AxUpdateEventInvitationDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AxMyService.MyEventsControllerDeclineWithAccessKeyPath, 'patch');
    if (params) {
      rb.path('invitationId', params.invitationId, {});
      rb.query('accessKey', params.accessKey, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `myEventsControllerDeclineWithAccessKey$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  myEventsControllerDeclineWithAccessKey(params: {
    invitationId: number;
    accessKey?: string;
    context?: HttpContext
    body: AxUpdateEventInvitationDto
  }
): Observable<void> {

    return this.myEventsControllerDeclineWithAccessKey$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
