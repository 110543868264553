import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { concatMap, Observable } from 'rxjs';
import { catchError, map, retryWhen, share, switchMap, tap } from 'rxjs/operators';
import { AxAuthService } from 'src/app/_generated/axova-rest-api/services/ax-auth.service';
import { AxTokenService } from './ax-token.service';

@Injectable()
export class AxApiTokenInterceptor implements HttpInterceptor {
  private retryRequest = Symbol('reload');
  private refreshToken = this.axTokenService.refreshToken$.asObservable()
    .pipe(
      concatMap(refreshToken => {
        return this.axAuthService
          .authControllerRefreshToken({ body: { refreshToken: refreshToken } })
          .pipe(
            map(response => {
              try {
                return JSON.parse(response as any);
              } catch (noNewTokensException) {
                return response;
              }
            }),
            tap(newTokenResponse => {
              if ((newTokenResponse as any).access_token && (newTokenResponse as any).refresh_token) {
                this.axTokenService.setAccessToken((newTokenResponse as any).access_token).then();
                this.axTokenService.setRefreshToken((newTokenResponse as any).refresh_token).then();
                throw this.retryRequest;
              }
            }),
            share(),
          ) as Observable<any>;
      }),
    );

  constructor(
    private axTokenService: AxTokenService,
    private axAuthService: AxAuthService,
    private router: Router,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const request$ = new Observable<HttpRequest<any>>(observer => {
      observer.next(this.addToken(request));
      observer.complete();
    });

    // @ts-ignore
    return request$.pipe(
      switchMap(req => next.handle(req)),
      catchError((error: Error) => {
        if (error instanceof HttpErrorResponse) {
          // Catch refresh token error, which does a logout.
          if (error.url.indexOf(AxAuthService.AuthControllerRefreshTokenPath) > -1) {
            this.axTokenService.logout().then();
            this.router.navigateByUrl('/').then();
            throw error;
          }
          switch (error.status) {
            case 401:
              return this.refreshToken;
            default:
              throw error;
          }
        } else {
          throw error;
        }
      }),
      retryWhen(err$ =>
        err$.pipe(
          tap(err => {
            if (err === this.retryRequest) {
              return;
            }
            throw err;
          }),
        ),
      ),
    );
  }

  /**
   * Adds the bearer token to the http request.
   *
   * @param req
   * @private
   */
  private addToken(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Authorization: 'Bearer ' + this.axTokenService.accessToken$.getValue(),
      },
    });
  }
}
