import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'profileImage'})
export class AxApiProfileimagePipe implements PipeTransform {
  transform(profileImageUrl: string) {
    if (!profileImageUrl) {
      return '';
    }
    try {
      const indexOfWebroot = profileImageUrl.lastIndexOf('/v4');
      const relativeUrl = profileImageUrl.substring(indexOfWebroot);
      return `https://axova-office.ch${relativeUrl}`; // TODO: Wechseln auf environment Variable.
    } catch (noProfileImageException) {
      return '';
    }
  }
}
